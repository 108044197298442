import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import './index.css';
import { NavList } from './nav-list';
import { BurgerMenuModal } from './burger-menu';
import { BurgerMenuCloseIcon, BurgerMenuIcon } from './svg/burger-menu';
import gsap from 'gsap';
import { AllCourses } from './all-courses';
import { ifClassExists } from '../../utils/if-class-exists';
import { addElementClass, removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarRootState } from './store/store';
import { allCoursesVisibleOff, allCoursesVisibleOn, animatedOff, animatedOn, modalBurgerVisibleOff, modalBurgerVisibleOn } from './store/slices/navbar-slice';
import { ModalContext } from '../../context/modal-context';
import { getFileUrl } from '../../api/url';
import { NavBarDto } from '../../api/interfaces/elements/nav-bar/nav-bar-dto';
import { useNavigate } from 'react-router-dom';
import { usePageType } from '../../hooks/use-page-type';
import { SplashScreenContext } from "../../context/splash-screen-context";
import { RelevantLink } from "../relevant-link";
import { DirectionContext } from "../../context/direction-context";
import { CourseContext } from "../../context/course-context";
import { CourseType } from "../../api/interfaces/course/course-type";
import Block_redirects from '../Block_header_redirects/Block_header_redir';
import Block_timer_price from '../Block_timer_price/Block_timer_price';

interface NavBarProps {
  navBar: NavBarDto | undefined;
  className: string | undefined;
}


export const NavBar: React.FC<NavBarProps> = (
  {
    navBar,
    className
  }
) => {
  const modalBurgerVisible = useSelector((state: NavBarRootState) => state.navBar.modalBurgerVisible);
  const allCoursesVisible = useSelector((state: NavBarRootState) => state.navBar.allCoursesVisible);
  const dispatch = useDispatch();

  const initialScrollY = useRef(0);
  const headerRef = useRef({} as HTMLHeadElement);

  const {
    showProperSignupStateModal
  } = useContext(ModalContext);

  useEffect(() => {
    window.addEventListener('scroll', e => {
      if (window.userManualScroll) {
        const currentScroll = window.scrollY;

        if (currentScroll >= 0 && currentScroll <= document.documentElement.scrollHeight) {
          if (currentScroll - initialScrollY.current > 20) {
            initialScrollY.current = currentScroll;
            if (headerRef.current) {
              headerRef.current.style.transform = 'translate(0px, -168px)';
            }
          }

          if (initialScrollY.current - currentScroll > 20) {
            initialScrollY.current = currentScroll;

            if (headerRef.current) {
              headerRef.current.style.transform = 'translate(0px, 0px)';
            }
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (modalBurgerVisible || allCoursesVisible) {
      addElementClass(document.body, 'modal-open');
      dispatch(animatedOff());
    } else {
      removeElementClass(document.body, 'modal-open');
      dispatch(animatedOn());
    }
  }, [
    allCoursesVisible,
    dispatch,
    modalBurgerVisible
  ]);

  useLayoutEffect(() => {
    setTimeout(() => {
      gsap.from('.header', {
        translateY: -100,
        ease: 'bounce.in'
      });

      gsap.to('.header', {
        translateY: 0
      });
    }, 950);
  }, []);

  useEffect(() => {
    const eventHandler = () => {
      if (window.innerWidth > 1400) {
        dispatch(modalBurgerVisibleOff());
      }
    };

    window.addEventListener('resize', eventHandler);

    return () => {
      window.removeEventListener('resize', eventHandler);
    };
  }, [
    dispatch
  ]);

  const pageType = usePageType();
  const navigate = useNavigate();

  const {
    setLoading
  } = useContext(SplashScreenContext);

  const {
    direction
  } = useContext(DirectionContext);

  const {
    courseData
  } = useContext(CourseContext);

  const logoId = useMemo(() => {
    if (courseData?.course) {
      switch (courseData.course.type) {
        case CourseType.plain:
          return direction?.styles.navLogoImageId;
      }
    }

    return direction?.styles.navLogoCoveredImageId;
  }, [courseData?.course, direction?.styles.navLogoCoveredImageId, direction?.styles.navLogoImageId]);

  let arrTimer = {
    "bim": [new Date('2024, 7, 9'), '#191760', '#00CCFF', '#fff'],
    "cad": [new Date('2024, 8, 6'), '#F93F37', '#00FF66', '#fff'],
    "code": [new Date('2024, 7, 4'), '#191760', '#00CCFF', '#fff'],
    "3d": [new Date('2024, 7, 4'), '#FFFF33', '#6E27B6', '#0D0D0D'],
    "md": [new Date('2024, 7, 4'), '#191760', '#00CCFF', '#fff'],
  };

  let dir = direction?.type;
  let stylearr = {

  }
  // if(dir === 'cad'){
  //   stylearr = {
  //     'margin-top': '58px'
  //   }
  // }

  return (
    <div style={{'width': 'inherit'}}>
    {/* {dir === 'cad' ? <Block_timer_price dateEnd={arrTimer[dir][0]} backColor={arrTimer[dir][1]} timerColor={arrTimer[dir][2]} colorText={arrTimer[dir][3]} /> : null} */}
    <header
      id='header'
      style = {stylearr}
      className={`header ${className}`}
      ref={headerRef}
    >
      <div className='header-wrapper'>
        <div className='header-wrapper-leftSide'>
          {courseData?.course == undefined ?
            <Block_redirects course={logoId} logo_up={getFileUrl(logoId)} /> :
            <RelevantLink
              to={"/catalog"}
              relative="path"
            >
              <div
                className='header-logo'
                onClick={() => {
                  setLoading(true);
                  dispatch(allCoursesVisibleOff());
                  dispatch(modalBurgerVisibleOff());
                }}
              >
                <img
                  src={getFileUrl(logoId)}
                  alt={logoId}
                />
              </div>
            </RelevantLink>


          }
          {/* <Block_redirects course={logoId} logo_up={getFileUrl(logoId)}/> */}
          {/* <RelevantLink 
            to={"/catalog"}
            relative="path" 
          >
            <div
              className='header-logo'
              onClick={() => {
                setLoading(true);
                dispatch(allCoursesVisibleOff());
                dispatch(modalBurgerVisibleOff());
              }}
            >
              <img
                src={getFileUrl(logoId)}
                alt={logoId}
              />
            </div>
          </RelevantLink> */}
        </div>

        <nav className='header-nav nav'>
          <NavList
            className={className}
            navBar={navBar}
          />
        </nav>

        <div className='header-wrapper-rightSide'>
          <button
            onClick={() => {
              dispatch(allCoursesVisibleOn());
              dispatch(modalBurgerVisibleOff());
            }}
            className={`${ifClassExists('header-button--active', allCoursesVisible)} header-button ${className} header-button--allcourse`}
          >
            Все курсы
          </button>
        </div>

        {allCoursesVisible && (
          <AllCourses />
        )}

        <div className={`header-menu`}>
          {modalBurgerVisible && (
            <>
              <div
                className={`menu-close-icon ${className}`}
                onClick={() => {
                  dispatch(modalBurgerVisibleOff());
                  dispatch(allCoursesVisibleOff());
                }}
              >
                <BurgerMenuCloseIcon />
              </div>

              <BurgerMenuModal
                openModal={() => {
                  dispatch(modalBurgerVisibleOff());

                  setTimeout(() => addElementClass(document.body, 'modal-open'), 300);
                }}
                className={className}
                navBar={navBar}
              />
            </>
          )}

          {allCoursesVisible && (
            <div
              className={`menu-close-icon ${className}`}
              onClick={() => {
                dispatch(allCoursesVisibleOff());
                dispatch(modalBurgerVisibleOff());
              }}
            >
              <BurgerMenuCloseIcon />
            </div>
          )}

          {!allCoursesVisible && !modalBurgerVisible && (
            <div
              className={`menu-close-icon ${className}`}
              onClick={() => {
                dispatch(modalBurgerVisibleOn());
              }}
            >
              <BurgerMenuIcon />
            </div>
          )}
        </div>
      </div>
    </header>
    </div>
  );
};
