import React, { useContext, useMemo } from 'react';
import { Frame } from '../../components/frame';
import { CustomButton } from '../../components/button';
import './index.css';
import { IdProps } from '../../interfaces/id-props';
import { ModalContext } from '../../context/modal-context';
import { CourseContext } from '../../context/course-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { PageType, usePageType } from '../../hooks/use-page-type';

interface ContactsFrameProps extends IdProps {
}

const Question = (
  <svg width='195' height='176' viewBox='0 0 195 176' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3329_8911)'>
      <circle cx='176' cy='145' r='145' fill='#141343'/>
      <path
        d='M152.53 118.754C152.53 114.505 153.087 111.189 154.201 108.805C155.417 106.421 157.139 104.504 159.368 103.053C161.597 101.602 164.991 99.8402 169.551 97.7675L170.159 97.4565C171.07 97.042 171.932 96.6793 172.742 96.3683C173.553 95.9538 174.312 95.5392 175.022 95.1247C177.656 93.881 179.632 92.8446 180.949 92.0155C182.367 91.1864 183.532 90.2537 184.444 89.2173C185.457 87.9736 186.166 86.5745 186.572 85.0199C187.078 83.3617 187.332 81.2889 187.332 78.8016C187.332 73.3088 185.66 69.215 182.316 66.5204C178.973 63.7222 174.059 62.3231 167.575 62.3231C160.584 62.3231 155.113 63.4631 151.162 65.7431C147.572 67.7203 144.819 70.9377 142.902 75.3953C142.548 76.2181 141.751 76.7762 140.855 76.7696L124.162 76.6459C122.759 76.6354 121.748 75.2911 122.194 73.9602C125.255 64.8374 130.301 58.0046 137.332 53.462C145.032 48.4873 155.012 46 167.271 46C180.341 46 190.371 48.6428 197.362 53.9283C204.454 59.2139 208 67.2459 208 78.0243C208 82.999 207.341 87.2481 206.024 90.7719C204.809 94.2956 202.934 97.2493 200.401 99.633C198.578 101.291 196.501 102.742 194.17 103.986C191.941 105.229 189.003 106.629 185.356 108.183C185.305 108.209 185.255 108.235 185.205 108.261C184.905 108.414 184.56 108.468 184.249 108.599C184.213 108.614 184.177 108.631 184.14 108.65C180.999 109.997 178.568 111.24 176.845 112.38C175.123 113.521 173.806 114.868 172.894 116.422C172.084 117.977 171.678 119.946 171.678 122.33V124.087C171.678 125.263 170.725 126.216 169.549 126.216H154.659C153.483 126.216 152.53 125.263 152.53 124.087V118.754Z'
        fill='#E3EAF7'/>
      <path
        d='M163.168 134.922C167.322 134.922 170.513 135.906 172.742 137.876C175.073 139.741 176.238 142.539 176.238 146.27C176.238 153.94 171.881 157.774 163.168 157.774C154.354 157.774 149.946 153.94 149.946 146.27C149.946 138.705 154.354 134.922 163.168 134.922Z'
        fill='#E3EAF7'/>
    </g>
    <defs>
      <clipPath id='clip0_3329_8911'>
        <rect width='195' height='176' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);

const Bag = (
  <svg width='195' height='176' viewBox='0 0 195 176' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3329_8925)'>
      <circle cx='176' cy='145' r='145' fill='#141343'/>
      <g clipPath='url(#clip1_3329_8925)'>
        <path
          d='M195.138 76.494H182.891V69.1458C182.891 59.6911 175.2 52 165.745 52H141.251C131.797 52 124.105 59.6911 124.105 69.1458V76.494H111.858C107.038 76.494 102.698 78.5026 99.582 81.7162L153.498 109.845L207.415 81.7162C204.299 78.5026 199.959 76.494 195.138 76.494ZM138.802 76.494V69.1458C138.802 67.7938 139.899 66.6964 141.251 66.6964H165.745C167.097 66.6964 168.195 67.7938 168.195 69.1458V76.494H138.802Z'
          fill='#E3EAF7'/>
        <path
          d='M156.9 124.651C154.774 125.758 152.227 125.758 150.111 124.651L94.7148 95.748V137.731C94.7148 147.186 102.406 154.877 111.861 154.877H195.14C204.595 154.877 212.286 147.186 212.286 137.731V95.748L156.9 124.651Z'
          fill='#E3EAF7'/>
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3329_8925'>
        <rect width='195' height='176' fill='white'/>
      </clipPath>
      <clipPath id='clip1_3329_8925'>
        <rect width='126' height='126' fill='white' transform='translate(91 40)'/>
      </clipPath>
    </defs>
  </svg>
);

export const ContactsFrame: React.FC<ContactsFrameProps> = (
  {
    id
  }
) => {
  const {
    showProperAskQuestionModal,
    showProperSignupStateModal
  } = useContext(ModalContext);

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const pageType = usePageType();

  const definingClassName = useMemo(() => {
    switch (pageType) {
      case PageType.COURSE:
        return `${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`
      case PageType.DIRECTION:
        return 'catalog';
    }
  }, [
    course,
    pageType
  ]);

  const RelevantButtons = useMemo(() => {
    switch (pageType) {
      case PageType.COURSE:
        switch (course!.type) {
          case CourseType.plain:
            return {
              QuestionButton: (
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  title='Связаться с нами'
                  classList={[
                    'button--green',
                    'border--offline-color',
                    'color--online-color-text'
                  ]}
                  onClick={() => showProperAskQuestionModal()}
                  arrowVisible
                  arrowClassName='color--offline-color'
                />
              ),
              BagButton: (
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  title='Оставить контакты'
                  classList={[
                    'button--green',
                    'border--offline-color',
                    'color--online-color-text'
                  ]}
                  onClick={() => showProperSignupStateModal({ tabIndex: 1 })}
                  arrowVisible
                  arrowClassName='color--offline-color'
                />
              )
            }
          case CourseType.refresher:
            return {
              QuestionButton: (
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  title='Связаться с нами'
                  classList={[
                    'button--blue',
                    'border--offline-color',
                    'color--online-color-text'
                  ]}
                  onClick={() => showProperAskQuestionModal()}
                  arrowVisible
                  arrowClassName="color--online-color-text"
                />
              ),
              BagButton: (
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  title='Оставить контакты'
                  classList={[
                    'button--blue',
                    'border--offline-color',
                    'color--online-color-text'
                  ]}
                  onClick={() => showProperSignupStateModal({ tabIndex: 1 })}
                  arrowVisible
                  arrowClassName="color--online-color-text"
                />
              )
            }
        }
        break;
      case PageType.DIRECTION:
        return {
          QuestionButton: (
            <CustomButton
              buttonType={"button"}
              type='button'
              title='Связаться с нами'
              classList={[
                'button--green',
                'border--offline-color',
                'color--online-color-text'
              ]}
              onClick={() => showProperAskQuestionModal()}
              arrowVisible
              arrowClassName="color--offline-color"
            />
          ),
          BagButton: (
            <CustomButton
              buttonType={"button"}
              type='button'
              title='Оставить контакты'
              classList={[
                'button--green',
                'border--offline-color',
                'color--online-color-text'
              ]}
              onClick={() => showProperAskQuestionModal()}
              arrowVisible
              arrowClassName="color--offline-color"
            />
          )
        }
    }
  }, [course, pageType, showProperAskQuestionModal, showProperSignupStateModal]);

  const ContactsFrameContent = useMemo(() => {
    switch (pageType) {
      case PageType.COURSE:
        return (
          <div className='contacts-frame'>
            <div className={`contacts-frame-item ${definingClassName}`}>
              <div className='contacts-frame-item-wrapper'>
                <div className='contacts-frame-item__title-wrapper'>
                  <div className={`contacts-frame-item__title ${definingClassName}`}>
                    Всё-таки остались <span>вопросы</span>?
                  </div>
                </div>
                <p className={`contacts-frame-item__text ${definingClassName}`}>
                  Смело пишите или звоните, вас ждёт персональная консультация
                </p>

                {RelevantButtons.QuestionButton}
              </div>

              <div className={`contacts-frame-item-img ${definingClassName}`}>
                {Question}
              </div>
            </div>

            <div className={`contacts-frame-item ${definingClassName}`}>
              <div className='contacts-frame-item-wrapper'>
                <div className='contacts-frame-item__title-wrapper'>
                  <div className={`contacts-frame-item__title ${definingClassName}`}>
                    Вы представляете <span>компанию</span>?
                  </div>
                </div>
                <p className={`contacts-frame-item__text ${definingClassName}`}>
                  Нам есть, что предложить — оставьте свой контакт, мы с вами свяжемся!
                </p>

                {RelevantButtons.BagButton}
              </div>

              <div className={`contacts-frame-item-img ${definingClassName}`}>
                {Bag}
              </div>
            </div>
          </div>
        );
      case PageType.DIRECTION:
        return (
          <div className='contacts-frame'>
            <div className={`contacts-frame-item ${definingClassName}`}>
              <div className='contacts-frame-item-wrapper'>
                <div className='contacts-frame-item__title-wrapper'>
                  <div className={`contacts-frame-item__title ${definingClassName}`}>
                    Хотите предложить <span>свой курс</span>?
                  </div>
                </div>
                <p className={`contacts-frame-item__text ${definingClassName}`}>
                  Мы всегда в поиске талантливых креаторов и будем рады сотрудничеству!
                </p>

                {RelevantButtons.QuestionButton}
              </div>

              <div className={`contacts-frame-item-img ${definingClassName}`}>
                {Question}
              </div>
            </div>

            <div className={`contacts-frame-item ${definingClassName}`}>
              <div className='contacts-frame-item-wrapper'>
                <div className='contacts-frame-item__title-wrapper'>
                  <div className={`contacts-frame-item__title ${definingClassName}`}>
                    Вы представляете <span>компанию</span>?
                  </div>
                </div>
                <p className={`contacts-frame-item__text ${definingClassName}`}>
                  Нам есть, что предложить — оставьте свой контакт, мы с вами свяжемся!
                </p>

                {RelevantButtons.BagButton}
              </div>

              <div className={`contacts-frame-item-img ${definingClassName}`}>
                {Bag}
              </div>
            </div>
          </div>
        );
    }
  }, [RelevantButtons.BagButton, RelevantButtons.QuestionButton, definingClassName, pageType]);

  return useMemo(() => {
    switch (pageType) {
      case PageType.COURSE:
        switch (course!.type) {
          case CourseType.plain:
            return (
              <Frame
                id={id}
                bgClassName='outer-background-margin'
                coverClassName={`no-padding no-border no-border-radius`}
              >
                {ContactsFrameContent}
              </Frame>
            );
          case CourseType.refresher:
            return (
              <Frame
                id={id}
                bgClassName='outer-background-margin'
                coverClassName={`no-border no-border-radius no-padding-y`}
                sectionClassName={`no-padding-y ${course!.type.toLowerCase()}`}
              >
                {ContactsFrameContent}
              </Frame>
            );
        }
        break;
      case PageType.DIRECTION:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`no-padding-y no-padding-x no-border no-border-radius`}
            sectionClassName={'no-padding-y'}
          >
            {ContactsFrameContent}
          </Frame>
        );
    }
  }, [ContactsFrameContent, course, id, pageType]);
};
