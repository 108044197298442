import { CourseType } from '../interfaces/course/course-type';
import { CourseFormat } from '../interfaces/course/course-format';

export const courseTypeFullNameMap = (
  courseType: CourseType,
  courseFormat: CourseFormat
) => {
  switch (courseType) {
    case CourseType.plain:
      switch (courseFormat) {
        case CourseFormat.online:
          return 'онлайн-курс';
        case CourseFormat.offline:
          return 'очный курс';
      }
      break;
    case CourseType.refresher:
      switch (courseFormat) {
        case CourseFormat.online:
          return 'онлайн-переподготовка';
      }
      break;
  }
};

export const courseFormatNameMap = (
  courseFormat: CourseFormat
) => {
  switch (courseFormat) {
    case CourseFormat.online:
      return 'Онлайн';
    case CourseFormat.offline:
      return 'В классе';
  }
};

export const courseTypeNameMap = (
  courseType: CourseType
) => {
  switch (courseType) {
    case CourseType.plain:
      return 'Курс';
    case CourseType.refresher:
      return 'Переподготовка';
  }
};


