import React, { useContext, useMemo } from 'react';
import './index.css';
import { BorderedTitle } from '../../components/bordered-title';
import { Frame } from '../../components/frame';
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import { Arrow } from '../../components/arrow';
import { colors } from '../../constants/colors';
import parse from 'html-react-parser';
import { Rating } from './rating';
import { courseTypeFullNameMap } from '../../api/mapper/map';
import { Label } from '../../components/label';
import { getFileUrl } from '../../api/url';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CustomButton } from '../../components/button';
import { Calendar } from '../../components/calendar';
import { ModalContext } from '../../context/modal-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { QuoteArea } from './quote-area';
import { CommentArea } from './comment-area';
import 'moment/locale/ru'
import { dateToString } from "../../utils/dates";
import { getClassDates } from "../../hooks/get-class-dates";
import { DirectionContext } from "../../context/direction-context";

interface IntroductionFrameProps extends IdProps {
}

export const IntroductionFrame: React.FC<IntroductionFrameProps> = ({ id }) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    direction
  } = useContext(DirectionContext);

  const {
    showProperSignupStateModal
  } = useContext(ModalContext);

  const {
    firstClassDate,
    lastClassDate
  } = getClassDates(course?.scheduleFrames?.at(0));

  const Buttons = useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        switch (course!.format) {
          case CourseFormat.online:
            return [
              <CustomButton
                buttonType={"button"}
                type='button'
                title='Хочу пройти'
                classList={[
                  'button--green',
                  'border--offline-color',
                  'color--online-color-text'
                ]}
                onClick={() => {
                  window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                  showProperSignupStateModal({ tabIndex: 0 });
                }}
                arrowVisible
                arrowClassName='color--offline-color'
              />
            ];
          case CourseFormat.offline:
            if (firstClassDate && lastClassDate) {
              return [
                <Calendar date={`${dateToString(firstClassDate)} – ${dateToString(lastClassDate)}`} />,
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  title='Расписание'
                  classList={[
                    'button--green',
                    'border--offline-color',
                    'color--online-color-text'
                  ]}
                  arrowVisible
                  arrowClassName='color--offline-color'
                  onClick={() =>
                    document.getElementById('schedule-frame')?.scrollIntoView({ behavior: 'smooth' })
                  }
                />
              ];
            }

            const relevantClassName = `${course?.type.toLowerCase()} ${course?.format.toLowerCase()}`

            return (
              <div className={`oc-groupSetup ${relevantClassName} introduction-frame`}>
                <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                  <g className="layer">
                    <title>Layer 1</title>
                    <path
                      d="m31,20c0,6.08 -4.92,11 -11,11c-6.08,0 -11,-4.92 -11,-11c0,-6.08 4.92,-11 11,-11c6.08,0 11,4.92 11,11zm-19.99,0c0,4.97 4.02,8.99 8.99,8.99c4.97,0 8.99,-4.02 8.99,-8.99c0,-4.97 -4.02,-8.99 -8.99,-8.99c-4.97,0 -8.99,4.02 -8.99,8.99z"
                      fill="" id="svg_1" />
                    <path
                      d="m19.88,14.29c-0.49,0 -0.88,0.4 -0.88,0.88l0,5.72c0,0 0,0.23 0.11,0.4c0.07,0.15 0.19,0.27 0.34,0.36l4.08,2.36c0.42,0.25 0.96,0.1 1.2,-0.32c0.25,-0.42 0.11,-0.96 -0.32,-1.21l-3.66,-2.11l0,-5.2c0,-0.49 -0.4,-0.88 -0.88,-0.88z"
                      fill="" id="svg_2" />
                  </g>
                </svg>

                Идёт набор в группу
              </div>
            );
        }
        break;
      case CourseType.refresher:
        switch (course!.format) {
          case CourseFormat.online:
            return [
              <CustomButton
                buttonType={"button"}
                type='button'
                title='Записаться сейчас'
                classList={[
                  'button--green',
                  'border--offline-color',
                  'color--online-color-text'
                ]}
                onClick={() => {
                  window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                  showProperSignupStateModal({ tabIndex: 0 });
                }}
                arrowVisible
                arrowClassName='color--offline-color'
              />,
              <CustomButton
                buttonType={"button"}
                type='button'
                title='Узнать подробности'
                classList={['button--transparent']}
                onClick={() => document.getElementById('composed-program-frame')?.scrollIntoView({ behavior: 'smooth' })}
              />
            ];
        }
        break;
    }
  }, [direction?.meta?.counter, direction?.meta?.signUpButtonYandexTargetName, firstClassDate, lastClassDate, course, showProperSignupStateModal]);

  return useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        return (
          <Frame
            id={id}
            coverClassName={`no-padding-x no-padding-y`}
            sectionClassName={`start-frame ${course!.type.toLowerCase()}`}
          >
            <div
              className={`programmPreview-firstScreen firstScreen ${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`}
            >
              <div className='firstScreen-info'>
                <div className='firstScreen-info-row'>
                  <Label classList={['labelBorder']}
                    courseType={courseTypeFullNameMap(course!.type, course!.format) ?? ''} />
                  <Rating value={course!.introductionFrame?.rating} />
                </div>

                <h2 className='firstScreen-info__title'>{parse(course!.name)}</h2>

                <div className='firstScreen-list'>
                  {(course?.introductionFrame?.shortFacts ?? []).map((shortFact, index) => (
                    <div key={index} className='firstScreen-list-item'>
                      <Arrow type='arrow-2' color={`${colors['--brand-itmo-green-lizard']}`} />
                      <div className='firstScreen-list-item-text'>
                        {shortFact}
                      </div>
                    </div>
                  ))}
                </div>

                <div className='firstScreen-buttons'>{Buttons}</div>
              </div>

              <div className='firstScreen-imgContainer'>
                <img
                  src={getFileUrl(course!.introductionFrame?.imageId ?? '')}
                  alt={course!.introductionFrame?.imageId}
                />
              </div>
            </div>

            <div className='programmPreview-secondScreen secondScreen'>
              <BorderedTitle
                titleClassList={['text-design-bim-primary-black', 'secondScreen__title']}
                bordered
              >
                {parse(course?.introductionFrame?.description ?? '')}
              </BorderedTitle>

              <ul className='secondScreen-list'>
                {(course?.introductionFrame?.generalFacts ?? []).map((generalFact, index) => (
                  <li className='secondScreen-list-item' key={index}>
                    <Arrow type='arrow-2' color={colors['--brand-itmo-bluetiful']} />
                    <div className='secondScreen-list-item__text'>{parse(generalFact)}</div>
                  </li>
                ))}
              </ul>
            </div>
          </Frame>
        );
      case CourseType.refresher:
        let style_second = {}
        if (course?.fullPath == "courses/refresher/online/software-tester") {
          style_second = { 'display': 'none' }
        }
        return (
          <Frame
            id={id}
            sectionClassName={`start-frame refresher-firstScreen`}
            coverClassName={`no-padding-x no-padding-y`}
          >
            <div
              className={`programmPreview-firstScreen firstScreen ${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`}
            >
              <div className='firstScreen-info'>
                <div className='firstScreen-info-fx'>
                  <Label classList={['labelBorder']}
                    courseType={courseTypeFullNameMap(course!.type, course!.format) ?? ''} />
                </div>

                <h2 className='firstScreen-info__title'>
                  {parse(course!.name)}
                </h2>

                <div className='firstScreen-info-row'>
                  <div className='firstScreen-info-row-item'>
                    <div className='firstScreen-info-row-item__name'>Общая оценка:</div>
                    <Rating value={course!.introductionFrame?.rating} />
                  </div>
                  <div className='firstScreen-info-row-item'>
                    <div className='firstScreen-info-row-item__name'>Длительность:</div>
                    <div className='firstScreen-info-row-item__val'>
                      {course!.introductionFrame?.duration}
                    </div>
                  </div>
                  <div className='firstScreen-info-row-item'>
                    <div className='firstScreen-info-row-item__name'>Старт:</div>
                    <div className='firstScreen-info-row-item__val'>
                      {course!.introductionFrame?.startDate}
                    </div>
                  </div>
                  <div className='firstScreen-info-row-item'>
                    <div className='firstScreen-info-row-item__name'>Формат:</div>
                    <div className='firstScreen-info-row-item__val'>
                      {course!.introductionFrame?.format}
                    </div>
                  </div>
                  <div className='firstScreen-info-row-item'>
                    <div className='firstScreen-info-row-item__name'>Обновление:</div>
                    <div className='firstScreen-info-row-item__val'>
                      {course!.introductionFrame?.updateDate}
                    </div>
                  </div>
                </div>

                <div className='firstScreen-info-textBlock'>
                  <p className='firstScreen-info__text'>
                    {parse(course?.introductionFrame?.fullDescription ?? "")}
                  </p>
                </div>

                <div className='firstScreen-buttons'>
                  {Buttons}
                </div>
              </div>
            </div>

            <div className='programmPreview-secondScreen secondScreen refresher-secondScreen' style={style_second}>
              <QuoteArea
                quoteFacts={course?.introductionFrame?.quoteFacts}>

                <ul className='secondScreen-list-refresher'>
                  {(course!.introductionFrame?.generalFacts ?? []).map((generalFact, index) => (
                    <li className='secondScreen-list-item' key={index}>
                      <Arrow type='arrow-2' color={colors['--brand-itmo-capri']} />
                      <div className='secondScreen-list-item__text'>
                        {parse(generalFact)}
                      </div>
                    </li>
                  ))}
                </ul>
              </QuoteArea>

              <CommentArea
                course={course}
                forCatalog={false}
              />
            </div>
          </Frame>
        );
    }
  }, [
    Buttons,
    course,
    id
  ]);
};
