import React, { FC, useContext, useEffect, useMemo } from 'react';
import { CourseContext } from '../../context/course-context';
import { OnlineCourse } from '../courses/online-course';
import { OfflineCourse } from '../courses/offline-course';
import { CourseType } from '../../api/interfaces/course/course-type';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { RefresherCourse } from '../refreshers/online-refresher';
import { NotFoundPage } from "../not-found";
import { getFileUrl } from "../../api/url";

interface CourseProps {
}

export const Course: FC<CourseProps> = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  useEffect(() => {
    document.querySelector("link[rel~='icon']")?.setAttribute("href", getFileUrl(course?.meta?.faviconImageId));
  }, [course?.meta?.faviconImageId]);

  const RelevantCourse = useMemo(() => {
    if (!course) {
      return <NotFoundPage/>;
    }

    switch (course?.type) {
      case CourseType.plain:
        switch (course?.format) {
          case CourseFormat.online:
            return <OnlineCourse/>;
          case CourseFormat.offline:
            return <OfflineCourse/>;
        }
        break;
      case CourseType.refresher:
        switch (course?.format) {
          case CourseFormat.online:
            return <RefresherCourse/>;
        }
        break;
    }
  }, [course]);

  return (
    RelevantCourse
  );
};
